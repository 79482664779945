@media screen and (max-width: 767px) {
    aside.navbar {
        display: none;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        /* Hide the side navigation on smaller screens */
    }

    .show-menu {
        aside.navbar {
            display: initial;
            position: absolute;
            width: 240px;
            height: calc(100vh - 3.5rem);
            z-index: 1050;
        }

        .page-wrapper {
            // opacity: 0.5;
            filter: blur(2px);
        }
    }

    .home.page {
        grid-template-columns: 100% 0fr;
    }

    .page-wrapper {
        grid-column: span 2;
    }

    .w-100-xs {
        width: 100%;
    }

    .h-num-placeholder {
        height: 20rem;
    }

    .w-paginate-placeholder {
        width: 100%;
    }

    .multilang-desktop {
        display: none
    }

    .multilang-mobile {
        display: inline-block;
    }

    .cs-status-container {
        height: 60px;
    }
    body {
        margin: 0;
        overflow: auto;
    }
}