.animated-column {
    transform: scale(1.05); /* Example animation */
    transition: transform 0.3s ease-in-out;
}

.chart-wrapper {
    position: relative;
    width: 100%;
    height: 400px; /* Set a fixed height */
}

.chart-wrapper > canvas {
    width: 100% !important;
    height: 100% !important;
}

.table {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    border-collapse: collapse;
}

.table thead th {
    font-weight: normal;
    border-bottom: 2px solid #ddd;
}

.table tbody td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.agent-info {
    display: flex;
    align-items: center;
}

.agent-image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 8px;
}

.agent-details {
    display: flex;
    align-items: center;
}

.status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 8px;
    flex-shrink: 0; /* Prevents the status dot from shrinking */
}

.status-dot.green {
    background-color: green;
}

.status-dot.blue {
    background-color: blue;
}

.status-dot.yellow {
    background-color: yellow;
}

.status-dot.red {
    background-color: red;
}

.text-nowrap {
    white-space: nowrap;
    border-bottom: 2px solid #ddd;
    color: rgb(96, 93, 93);
}


.profile {
    position: relative;
    display: inline-block;
}

.status {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
}


