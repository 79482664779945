// Bootstrap class override

.dropdown-toggle:after {
    transform: rotate(0deg) !important;
    margin-bottom: -3px;
}

.file-feedback{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 85.714285%;
    color: var(--tblr-lime);
}