@media screen and (min-width: 768px) {
    .page-container-body-withscroll {
        max-height: 100vh;
        overflow-y: auto;
    }

    .h-num-placeholder {
        height: 16rem;
    }

    .w-paginate-placeholder {
        width: 30%;
    }

    .multilang-desktop {
        display: inline-block
    }

    .multilang-mobile {
        display: none;
    }

    .w-md-50 {
        width: 50%;
    }

    .mw-80 {
        max-width: 80%;
    }

    .mw-50 {
        max-width: 50%;
    }

    .minh-100 {
        min-height: 100%;
    }

    .mh-status {
        min-height: 105px;
    }

    .container-without-padding {
        padding-left: 0;
        padding-right: 0;
    }
}