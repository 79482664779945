:root {
    --tblr-font-sans-serif: 'Inter';
    --tblr-primary: #0086d7;
    --tblr-primary-rgb: 0, 135, 215;
}

@import 'react-toastify/dist/ReactToastify.css';
@import 'primereact/resources/themes/lara-light-cyan/theme.css';
@import "@tabler/core/dist/css/tabler.css";
@import 'animate.css';
@import "./custom.scss";
@import "./mobile.scss";
@import "./desktop.scss";
@import "./overrides.scss";
// @import 'bootstrap/dist/css/bootstrap.min.css';

