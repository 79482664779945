body {
    margin: 0;
    overflow: hidden;
}

.home.page {
    display: grid;
    grid-template-columns: 15rem 1fr;
    /* Set the width of the side navigation to 200px */
    grid-template-rows: auto 1fr;
    grid-template-areas: "top top" "nav body" ". body";
    height: 100vh;
}

header {
    grid-area: top;
    z-index: 1040;
    width: 100vw;
}

aside {
    grid-area: nav;
    // padding-top:3.5rem !important;
    overflow-y: auto !important;
}

.page-wrapper {
    position: relative;
    grid-area: body;
    overflow-y: auto;
    margin: 0 !important;
}

.navbar .navbar-nav .nav-link {
    justify-content: start;
}

.page-header {
    .btn {
        padding: 7px 1rem;
    }
}

.text-center {
    text-align: center !important;
}

.btn-xl {
    font-size: 2rem;
}

.btn-primary-lt {
    background-color: var(--tblr-primary-lt) !important;
    border-color: var(--tblr-primary) !important;
    color: var(--tblr-primary) !important;

}

.dropdown-toggle:after {
    content: none;
}

// .dropdown-item:hover {
//     background-color: #00000010; 
//     // color: #495057;
//   }

// .dropdown-item {
//     padding: 0.5rem 1rem;
//   }

.lang-theme-toggle {
    // position: absolute;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    z-index: 1040;
}

.navbar-entry {
    --tblr-navbar-border-color: 'transparent' !important;
    --tblr-navbar-bg: 'transparent' !important;
    position: absolute;
}

.pointing {
    cursor: pointer;
}

.separator {
    border-bottom: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
}

.h-paginate-placeholder {
    height: 40px;
}

.form-leading-image {
    height: 3rem;
}

.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: var(--tblr-body-bg);
}

.accordion-header-label {
    margin-top: -16px;
}

.uc-stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.uc-stepper-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.uc-stepper-item {
    position: relative;
    padding-bottom: 30px;
}

.uc-stepper-item:last-child {
    padding-bottom: 0;
}

.uc-stepper-item::before {
    font-weight: bold;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fb3232;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.uc-stepper-item.completed::before {
    background-color: #28a745;
    font-weight: bold;
    color: white;
}

.uc-stepper-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0%;
    transform: translateX(-50%);
    height: calc(100% - 0px);
    width: 2px;
    background-color: #ccc;
    z-index: 0;
}

.uc-stepper-item.completed::after {
    background-color: #6d6d6d;
}

.uc-stepper-content {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.uc-stepper-icon {
    position: absolute;
    left: -11px;
    border-radius: 100%;
    background-color: #fb3232;
    z-index: 999;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uc-stepper-icon.completed {
    position: absolute;
    left: -11px;
    border-radius: 100%;
    background-color: #28a745;
    z-index: 999;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uc-stepper-label {
    font-weight: bold;
}

.uc-stepper-desc {
    font-size: 0.8em;
}

.uc-stepper-action {
    text-align: start;
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    margin-top: 10px;
    color: var(--tblr-primary);
    font-weight: 600;
}

.approval-error {
    color: var(--tblr-danger);
}

// connect state component styles
.cs-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.cs-status-line {
    background-color: #6d6d6d;
    height: 2px;
    width: 100%;
    margin: 0px 14px;
}

.cs-status-dot {
    position: absolute;
    border-radius: 100%;
    background-color: #fb3232;
    z-index: 999;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs-status-dot.completed {
    position: absolute;
    border-radius: 100%;
    background-color: #28a745;
    z-index: 999;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs-status-label {
    position: absolute;
    bottom: 0px;
    font-size: 0.8em;
}

// Description item component styles
.dic-point {
    background: rgba(var(--tblr-muted-rgb));
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    font-weight: bold;
}

.btn-light-blue {
    padding: 0.3625rem 0.5625rem;
    width: 100%;
}

.coming-soon {
    background-color: var(--tblr-body-bg);
    color: var(--tblr-body-color);
    opacity: 0.7;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.avatar-placeholder {
    min-width: 42px;
}

.xxl-normal-font {
    font-size: 1.5rem;
    font-weight: 400;
}

.xl-normal-font {
    font-size: 1rem;
    font-weight: 400;
}

.md-light-font {
    font-size: 0.8rem;
    font-weight: 200;
}



.react-datepicker-popper {
    min-width: 300px !important;
}

.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
        width: 100%;
    }
}

.react-datepicker__time-container {
    width: 80px !important;

    .react-datepicker__time {
        .react-datepicker__time-box {
            width: 80px !important;
        }
    }
}

// override the default theme x icon when applies form invalid
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

//add table borders
.table {
    border-collapse: collapse;
    width: 100%;

    th, td {
        border: 1px solid #dddddd85; //color
        padding: 8px; // padding
    }

//     th {
//         background-color: #f2f2f2; //background color
//     }

//     tr:nth-child(even) {
//         background-color: #f9f9f9; // row striping
//     }

//     tr:hover {
//         background-color: #f1f1f1; //hover color
// }
}

//ivr number pad placeholder
.square-btn {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}
